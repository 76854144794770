class PaginatorParams {
    static get PAGE() {
        return 'p[page]'
    }

    static get PER_PAGE() {
        return 'p[per_page]'
    }

    static get TYPE() {
        return 'p[type]'
    }
}

class SearchParams {
    static get QUERY() {
        return 'query'
    }
}

class SortParams {
    static get SORT() {
        return 's'
    }
}

function asElement(element) {
    if (element instanceof HTMLElement) {
        return element;
    } else if (typeof element === typeof '') {
        return document.getElementById(element);
    }
    throw new TypeError("Element should be instance of HTMLElement or string");
}

let _apiURL = null;
let _staticURL = null;
const Meta = {
    apiURL() {
        if (_apiURL === null) {
            const meta = document.querySelector('meta[name="apiserver"]');
            _apiURL = meta ? meta.getAttribute('content') : '';
        }
        return _apiURL;
    },
    staticURL() {
        if (_staticURL === null) {
            const meta = document.querySelector('meta[name="static_root"]');
            _staticURL = meta ? meta.getAttribute('content') : '/static/';
        }
        return _staticURL;
    },
    get(key) {
        const meta = document.querySelector(`meta[name="${key}"]`);
        return meta ? meta.getAttribute('content') : null;
    }
};

class Context {
    static push(url, context=null) {
        window.history.pushState({context: context || document.body.dataset['context']}, '', url);
    }
    static replace(url, context=null) {
        window.history.pushState({context: context || document.body.dataset['context']}, '', url);
    }
}

class Ajax {
    static urlEncode(_data) {
        // return Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&');
        let data = {};
        if (_data instanceof Map) {
            _data.forEach ((v,k) => { data[k] = v; });
        } else {
            data = _data;
        }
        let parts = [];
        for (let k of Object.keys(data).sort()) {
            if (typeof data[k] === typeof []) {
                for (let v of data[k]) {
                    parts.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
                }
            } else {
                parts.push(`${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`);
            }
        }
        return parts.join('&');
    }
    static urlDecode(query="") {
        if (query.trim() === "") {
            return {};
        }
        const parts = query.split('&');
        let data = {};
        for (let part of parts) {
            let [k, v] = part.split('=');
            k = decodeURIComponent(k);
            if (k in data) {
                if (typeof data[k] !== typeof []) {
                    data[k] = [data[k]];
                }
                data[k].push(decodeURIComponent(v));
            } else {
                data[k] = decodeURIComponent(v);
            }

        }
        return data;
    }

    static queryString() {
        return window.location.search.substr(1)
    }

    static queryParts() {
        return new Set(window.location.search.substr(1).split('&'));
    }

    static makeUrlStr(url, query) {
        if (query) {
            return `${url}?${query}`;
        }
        return url;
    }

    static makeUrl(url, data) {
        let fullUrl = url;
        let query = Ajax.urlEncode(data);
        if (query) {
            fullUrl = `${url}?${query}`;
        }
        return fullUrl;
    }

    static async submitForm(form, data='', ajax_header = true) {
        if (!data) {
            data = {};//new FormData(form);
            for (let i=0; i<form.elements.length;++i) {
                let name = form.elements[i].name;
                if (name) {
                    data[name] = form.elements[i].value;
                }
            }
        }
        let headers = new Headers();
        if (ajax_header) {
            headers.append('X-Requested-With', 'XMLHttpRequest');
            headers.append('X-Page-Context', document.body.dataset['context'] || '');
            let csrfCookie = Meta.get('csrf_token');//document.cookie.match(/csrftoken=([\w-]+)/);
            if (csrfCookie) {
                headers.append('X-Csrf-Token', Meta.get('csrf_token'));
            }
        }
        let method = (form.getAttribute('method') || 'POST').toLowerCase();
        let response = await Ajax[method](
            form.getAttribute('action'),
            data, {
                ajax_header: ajax_header
            });

        if (response.status === 200) {
            // form.reset();
            return response.text();
        } else {
            throw response;
        }
    }
    static async submitFormJson(form, data=null, ajax_header = true) {
        if (!data) {
            data = {};//new FormData(form);
            for (let i=0; i<form.elements.length;++i) {
                let name = form.elements[i].name;
                if (name) {
                    data[name] = form.elements[i].value;
                }
            }
        }
        let method = (form.getAttribute('method') || 'POST').toLowerCase();
        let response = await Ajax[method](
            form.getAttribute('action'),
            data, {
                ajax_header: ajax_header,
                accept: 'application/json'
            });

        if (response.status === 200) {
            // form.reset();
            return response.json();
        } else {
            throw response;
        }
    }

    static async load(url, data, container, opts={}) {
        let response = await Ajax.get(url, data, {ajax_header:true}, {
            'X-Page-Context': document.body.dataset['context'] || '',
            'X-Override-Context': opts.context || ''
        });
        // let response = await fetch(url, {
        //     headers: new Headers({
        //         'X-Requested-With': 'XMLHttpRequest',
        //     }),
        //     credentials: 'same-origin'
        // });

        asElement(container).innerHTML = await response.text();

        return response;
    }

    static async loadPage(url, container, history=true) {
        let response = await fetch(url, {
            headers: new Headers({
                'X-Requested-With': 'XMLHttpRequest',
                'X-Page-Context': document.body.dataset['context'] || ''
            }),
            credentials: 'same-origin'
        });

        let old_context = document.body.dataset['context'];
        let new_context = response.headers.get('X-Page-Context') || '';
        document.body.dataset['context'] = new_context;

        const in_context = (old_context === new_context);

        asElement(container).innerHTML = await response.text();
        // if (ajaxDomain) {
        //     window.history.pushState({domain: ajaxDomain}, '', url);
        // }
        if (in_context && history) {
            // window.history.pushState({context: new_context}, '', url);
            Context.push(url, new_context);
        }
        return response;
    }

    static async loadFeed(url, data={}, {append, context_container, page_container, no_context}) {
        // let response = await fetch(url, {
        //     headers: new Headers({
        //         'X-Requested-With': 'XMLHttpRequest',
        //         'X-Page-Context': document.body.dataset['context'] || ''
        //     }),
        //     credentials: 'same-origin'
        // });
        let response = await Ajax.get(url, data);

        let old_context = document.body.dataset['context'];
        let new_context = response.headers.get('X-Page-Context') || '';
        document.body.dataset['context'] = new_context;

        const in_context = (old_context === new_context);

        let container;
        if (in_context) {
            container = context_container || document.querySelector('.ajax_context_container');
        } else {
            container = page_container || document.querySelector('.ajax_page_container');
        }

        try {
            let paginator = JSON.parse(response.headers.get('X-Paginator'));
            container.dataset.count = paginator.total || 0;
            // TODO: full paginator
        } catch (e) {
            // do nothing
        }

        let part = await response.text();
        if (append) {
            asElement(container).insertAdjacentHTML('beforeEnd', part);
        } else {
            asElement(container).innerHTML = part;
        }
        // if (ajaxDomain) {
        //     window.history.pushState({domain: ajaxDomain}, '', url);
        // }
        if (in_context && !no_context) {
            delete data['p[page]'];
            delete data['p[per_page]'];
            delete data['p[type]'];
            // window.history.pushState({context: new_context}, '', Ajax.makeUrl(url, data));
            Context.push(Ajax.makeUrl(url, data), new_context);
        }
        return response;
    }

    static async _post_request(method, url, data={}, opts={}) {
        if (opts.ajax_header===undefined) {
            opts.ajax_header = true;
        }
        if (opts.content_type===undefined) {
            opts.content_type = 'application/x-www-form-urlencoded';
        }

        let headers = new Headers();

        if (data instanceof FormData) {
           data.set('csrftoken', Meta.get('csrf_token'));
        } else {
            data['csrftoken'] = Meta.get('csrf_token');
            switch (opts.content_type) {
                case 'application/x-www-form-urlencoded':
                    data = Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&');
                    break;
                case 'application/json':
                    data = JSON.stringify(data);
                    break;
            }
            headers.set('content-type', opts.content_type);
        }

        if (opts.accept) {
            headers.set('Accept', opts.accept);
        }
        if (opts.ajax_header) {
            headers.append('X-Requested-With', 'XMLHttpRequest');
            headers.append('X-Page-Context', document.body.dataset['context'] || '');
            let csrfCookie = Meta.get('csrf_token');//document.cookie.match(/csrftoken=([\w-]+)/);
            if (csrfCookie) {
                headers.append('X-Csrf-Token', Meta.get('csrf_token')/*csrfCookie[1]*/);
            }
        }
        // if (method.toUpperCase() !== 'POST') {
        //     headers.append('X-Http-Method-Override', method);
        // }
        // Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')
        let response = await fetch(url, {
            method: method,
            credentials: 'same-origin',
            body: data,
            headers: headers
        });
        if (response.status == 200) {
            return response;
        } else {
            throw response;
        }
    }

    static async put(url, data={}, opts={}) {
        return Ajax._post_request('PUT', url, data, opts);
    }

    static async delete(url, data={}, opts={}) {
        return Ajax._post_request('DELETE', url, data, opts);
    }

    static async patch(url, data={}, opts={}) {
        return Ajax._post_request('PATCH', url, data, opts);
    }

    static async post(url, data={}, opts={}) {
        return Ajax._post_request('POST', url, data, opts);
    }

    static async get(url, data={}, opts={}, override_headers={}) {
        if (opts.ajax_header===undefined) {
            opts.ajax_header = true;
        }

        let headers = new Headers();
        if (opts.accept) {
            headers.set('Accept', opts.accept);
        }
        if (opts.ajax_header) {
            headers.append('X-Requested-With', 'XMLHttpRequest');
            headers.append('X-Page-Context', document.body.dataset['context'] || '');
        }
        if (override_headers) {
            for (let header in override_headers) {
                headers.set(header, override_headers[header]);
            }
        }
        let [url_path, url_query] = url.split('?');

        if (data instanceof FormData) {
            let d = [];
            if (url_query) {
                d.push(url_query);
            }
            let enc = Ajax.urlEncode(data);
            if (enc) {
                d.push(enc);
            }
            data = d.join('&');
        } else {
            data = Object.keys(data).length?[Ajax.urlEncode(data)]:[];

            if (url_query) {
                data.unshift(url_query);
            }
            data = data.join('&');

        }
        if (data) {
            data = `?${data}`;
        }
        let response = await fetch(`${url_path}${data}`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: headers
        });
        if (response.status == 200) {
            return response;
        } else {
            throw response;
        }
    }
}


window.Ajax = Ajax;

function getInternetExplorerVersion() {
    var rv = -1;
    if (navigator.appName == 'Microsoft Internet Explorer') {
        var ua = navigator.userAgent;
        var re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
        if (re.exec(ua) != null)
            rv = parseFloat(RegExp.$1);
    }
    else if (navigator.appName == 'Netscape') {
        var ua = navigator.userAgent;
        var re = new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})");
        if (re.exec(ua) != null)
            rv = parseFloat(RegExp.$1);
    }
    return rv;
}



if(!document.ELEMENT_NODE) {
	document.ELEMENT_NODE = 1;
	document.ATTRIBUTE_NODE = 2;
	document.TEXT_NODE = 3;
	document.CDATA_SECTION_NODE = 4;
	document.ENTITY_REFERENCE_NODE = 5;
	document.ENTITY_NODE = 6;
	document.PROCESSING_INSTRUCTION_NODE = 7;
	document.COMMENT_NODE = 8;
	document.DOCUMENT_NODE = 9;
	document.DOCUMENT_TYPE_NODE = 10;
	document.DOCUMENT_FRAGMENT_NODE = 11;
	document.NOTATION_NODE = 12;
}

if(!document.createElementNS) {
	document.createElementNS = function(namespaceURI, qualifiedName) {
		return document.createElement(qualifiedName);
	};
}

/**
 * Throttle observable events
 *
 * @example
 * ```js
 * // @flow
 * throttle(new Observable((observer: SubscriptionObserver) => {
 *     observer.next('1')
 *     observer.next('2')
 * }), 100)
 *
 * // outputs: 2
 * ```
 */


function listen(element, eventName, selector, bubbles=true) {
    if (typeof element === typeof '') {
        element = document.querySelector(element);
    }

    if (!element) return new Observable(observer => {return () => {};});

    return new Observable(observer => {
        // Create an event handler which sends data to the sink
        let handler = (event) => {
            let foundMatch = false;
            if (selector) {
                let node = event.target;
                while (!foundMatch && node && node !== document.body) {
                    foundMatch = node.matches(selector);
                    if (foundMatch) { break; }
                    node = node.parentNode;
                }
                if (foundMatch) {
                    event.observed = node;
                }
            } else {
                event.observed = element;
                foundMatch = true;
            }
            if (foundMatch) {
                observer.next(event);
            }
        };

        // Attach the event handler
        if (typeof eventName === typeof []) {
            for (let event of eventName) {
                element.addEventListener(event, handler, bubbles);
            }
        } else {
            element.addEventListener(eventName, handler, bubbles);
        }

        // Return a cleanup function which will cancel the event stream
        return () => {
            // Detach the event handler from the element
            if (typeof eventName === typeof []) {
            for (let event of eventName) {
                element.removeEventListener(event, handler, true);
            }
        } else {
            element.removeEventListener(eventName, handler, true);
        }
        };
    });
}

class DomObserver {
    constructor(element) {
        if (typeof element === typeof '') {
            this.node = document.querySelector(element);
        } else {
            this.node = element;
        }
    }

    listen(event, selector, bubbles=false) {
        return listen(this.node, event, selector, bubbles);
    }

    publish(event, data) {
        return dispatchEvent(this.node, event, data);
    }
}
function $$(el) {
    return new DomObserver(el);
}

function dispatchEvent(node, event, data) {
    let e;
    if (getInternetExplorerVersion() <= 11) {
        e = document.createEvent('CustomEvent');
        e.initCustomEvent(event, false, true, data);
    } else {
        e = new CustomEvent(event, {detail: data});

    }
    node.dispatchEvent(e);
}

class EventObserver {
    constructor() {
        this.node = document.createElement('div');
        this.observables = new Map();
    }

    publish(event, data) {
        dispatchEvent(this.node, event, data);
    }

    listen(event) {
        let observable;
        if (this.observables.has(event)) {
            observable = this.observables.get(event);
        } else {
            observable = listen(this.node, event).map((e)=>{return e.detail;});
            this.observables.set(event, observable);
        }
        return observable;
    }
}

class ObserverContainer {
    constructor() {
        this.subscriptions = {};
    }
    destroy() {
        for (let k in this.subscriptions) {
            this.subscriptions[k].unsubscribe();
        }
    }
    unsubscribe(k) {
        if (this.subscriptions[k]) {
            this.subscriptions[k].unsubscribe();
        }
    }
}

const EVENT_OBSERVER_SYMBOL = 'EVENT_OBSERVER';//Symbol('eventObserver');
// window.EVENT_OBSERVER_SYMBOL = Symbol('eventObserver');

function eventObserver() {
    if (!window[EVENT_OBSERVER_SYMBOL]) {
        window[EVENT_OBSERVER_SYMBOL] = new EventObserver();
    }
    return window[EVENT_OBSERVER_SYMBOL];
}

// Ленивая загрузка изображений
// img[data-src], div[data-lazybg]
function watchElement(entries, observer) {
    entries.forEach(entry=>{
        if (entry.isIntersecting) {
            let el = entry.target;
            let imgSrc = el.dataset['src'];
            let img = new Image();
            /* preloading image */
            img.onload = function () {
                if (el.tagName == 'DIV') {
                    el.style.backgroundImage = "url('" + imgSrc + "')";
                } else {
                    el.src = imgSrc;
                }
                el.classList.remove('lazy_load');
                if (observer) {
                    observer.unobserve(el);
                }
            };
            img.src = imgSrc;
        }
    });
}

const lazyLoadObserver = new IntersectionObserver(watchElement, {
    rootMargin: '0px',
    threshold: 0.01
});

function initLazy(parent) {
    let elements = parent.querySelectorAll('img.lazy_load[data-src], div.lazy_load[data-src]');
    for (let i = 0; i < elements.length; i++){
        let el = elements[i];
        lazyLoadObserver.observe(el);
        // if (isElementInViewport(el)) {
        //     watchElement(el);
        // } else  {
        //     let watcher = scrollMonitor.create(el);
        //     watcher.enterViewport(watchElement.bind(this, el, watcher));
        // }
    }
}

class IndexGrid extends ObserverContainer {
    static get PAGINATOR_PARAMS(){ return [PaginatorParams.PAGE, PaginatorParams.PER_PAGE, PaginatorParams.TYPE]; }
    static get SORTER_PARAMS(){ return [SortParams.SORT]; }
    static get SEARCH_PARAMS(){ return [SearchParams.QUERY]; }

    constructor(gridNode) {
        super();
        this.gridContainer = asElement(gridNode);
        this.gridNode = gridNode.querySelector('.grid');
        this._showMoreObserver = $$(this.gridContainer).listen('click', '.grid-show_more_button');

        initLazy(this.gridNode);

        this._showMoreObserver.subscribe(async (event)=>{
            event.preventDefault();

            event.observed.innerText = event.observed.dataset.loadingText;
            await this.loadItems({}, true);
            event.observed.innerText = event.observed.dataset.text;
        });
    }

    subscribe(event, fn) {
        eventObserver().listen(event).subscribe(fn.bind(this));
    }

    loadItems(params={}, append=false) {
        params = Object.assign(Ajax.urlDecode(window.location.search.substr(1)), params);

        if (!append) {
            this.gridNode.dataset.page = 1;
            this.gridNode.dataset.nextIndex = 1;
        }
        Object.assign(params, {
            [PaginatorParams.PAGE]: this.gridNode.dataset.nextIndex,
            [PaginatorParams.PER_PAGE]: this.gridNode.dataset.perPage,
            [PaginatorParams.TYPE]: 'index'
        });

        if (this.gridNode.dataset.sortOrder) {
            params[SortParams.SORT] = this.gridNode.dataset.sortOrder;
        }

        return Ajax.loadFeed(this.gridNode.dataset.url, params, {
            append: append,
            context_container: this.gridNode,
            page_container: this.gridNode,
            no_context: true
        }).then(itemsLength => {
            this.gridNode.dataset.nextIndex = this.gridNode.childElementCount+1;
            this.gridNode.dataset.page = this.gridNode.childElementCount+1;

            initLazy(this.gridNode);

            if (Number(this.gridNode.dataset.nextIndex) > Number(this.gridNode.dataset.count)) {
                let buttons = this.gridContainer.querySelectorAll('.grid-show_more_button');
                for (let i=0; i<buttons.length; ++i) {
                    buttons[i].setAttribute('hidden', 'hidden');
                }
            } else {
                let buttons = this.gridContainer.querySelectorAll('.grid-show_more_button');
                for (let i=0; i<buttons.length; ++i) {
                    buttons[i].removeAttribute('hidden');
                }
            }

            return itemsLength;
        });
    }
}

const $grid = document.querySelector('.suggestions-grid');
let grid = new IndexGrid($grid);
